const cloudinaryCloudName = "ddjpyyflh";
export const environment = {
	production: true,
	baseUrl: "https://productionv4.rideplus.in/voilaChois/",
	apiKey: "AIzaSyD1PNZLX3hg6EEoZ-qVPULs6IJ9DNSBzj4", // - new - choistaxi@gmail.com
	mqtt: {
		hostName: "mqtt.rideplus.in",
		path: "/mqtt",
		protocol: "wss",
		clean: true,
		connectTimeout: 4000,
		clientId: "dispatchermqtt_" + Math.random().toString(16).substr(2, 8),
		userName: "admin",
		password: "xRtbMMo4Wb14pHFPU3yCd5qm67TisyOwyZNOo4iZA4PtXQ7mK35tuyN",
		reconnectPeriod: 1000,
		keepalive: 65535
	},
	ForceApiKey: "4ji9RJQJCWZLRq0vV7RuaupqqO0shxWogK8n7NyD5QoBj4dOBs",
	cloudinaryPresetName: "drmbyplf",
	cloudinaryBaseUrl: `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/upload`,

	driverOfflineCachingDuration: 30 * 60 * 1000, // 30 minutes
	tarrif: "automatic",
	OnEventShowShimmer: true,
	unassignBookingAlertTime: 60, // minutes
	unassignBookingAlertOffTime: 1440, // minutes
	calculateUnAssignBookingAlertTimeBasedOn: "pickupTime",
	roundUpNumber: 2,
	priceRoundUpNumber: 3,
	encryptSecretKey: "3333505423F69720BF108649D731DBAB",
	imageUrl: "media/img/fetch/",
	role: 3,
	type: 2,
	device: 3,
	isCopyAccountId: false,
	isShowAddressType: 'address', // address, area
	findDriverSettings: {
		'type': 'variant',
		'variant': 5, // min - 5, max - 50
		'count': 5, // max - 200
	},
	locationLimitation: 50,
	passengerLimitation: 50,
	minimumAssignMinutes: 5,

	isSetDriverLimit: false,
	showNearestDriveOnCreateBooking: false,

	manageAlertPopup: {
		'offlineDriver': true,
		'onlineDriver': true,
		'offlineDriverWarning': true,
	},

	CompanySetting: "CompanySetting/",
	Dispatcher: "Dispatcher/",
	DispatcherCars: "DispatcherCars/",
	boxItemImages: 'box-item-images@',
	Cars: "Cars/",
	Driver: "Driver/",
	waitTimeColor: "#059669",
	waitTimeDelayColor: "#991b1b",
	defaultColor: ['#57addc', '#58dcbd', '#61c58a', '#e91f63', '#9d27b0', '#266bb0'],
	driverStatusColors: {
		// 'accept':'#9f61ff',
		// 'arrived':'#393342',
		// 'onRoute':'#ff3030',
		// 'slideToComplete':'#ffad4f',
		// 'free':'#1becac'

		'accept': '#4c1d95',
		'arrived': '#000000',
		'onRoute': '#991b1b',
		'slideToComplete': '#d87606',
		'free': '#059669',
		'break': '#95c706'
	},
	zoneColorInMap: {
		'createdOneSideZone': 'purple',
		'surgeZone': 'yellow',
		'autoCreatedZone': 'blue',
		'createdZone': 'skyblue',
		'boundryZone': 'green',
		'offerZone': 'pink'
	},
	driverAssignColors: {
		// 'assign':'#638cff'

		'assign': '#1d4ed8'
	},
	bookingUnassignTimeColors: {
		// 'timeOut':'#ff3030',
		// 'warning':'#ffad4f',
		// 'remainTime':'#638cff'

		'timeOut': '#991b1b',
		'warning': '#d87606',
		'remainTime': '#1d4ed8'
	},
	pickupDelay: { 'time': 1, 'color': '#00c700' },
	dropDelay: { 'time': 1, 'color': '#ff0000' },
	paymentDelayTime: { 'time': 1, 'color': '#ff9214' },
	onlineDriverListSortBy: {
		freeDriverList: 'desc',
		acceptedBookingDriverList: 'desc',
		ontripBookingDriverList: 'desc',
		completedBookingDriverList: 'desc'
	},
	driverMarkerLabel: {
		isShow: false,
		color: '#7985c9',
		fontFamily: '',
		fontSize: '14px',
		fontWeight: 'bold',
	},
	driverMarkerIcon: {
		height: 40,
		width: 30,
		labelOrigin: { x: 0, y: -5 },
		anchor: { x: 50, y: 19 }

		// height: 40,
		// width: 70,
		// labelOrigin: { x: 30, y: 20 },
		// anchor: { x: 50, y: 19 }
	},
	windowPopupStyle: {
		'searchJob': {
			top: '52px',
			width: '800px',
			height: '82vh',
		},
		'closeJob': {
			top: '52px',
			width: '800px',
			height: '82vh',
		},
		'customers': {
			top: '52px',
			width: '1000px',
			height: '82vh',
		},
		'bookingDetails': {
			top: '52px',
			width: '500px',
			height: '82vh',
		},
		'alert': {
			top: '52px',
			width: '800px',
			height: '82vh',
		},
		'sticky': {
			top: '52px',
			width: '1300px',
			height: '82vh',
		},
		'chat': {
			top: '52px',
			width: '1000px',
			height: '65vh',
		},
		'driverInfo': {
			top: '52px',
			width: '800px',
			height: '50vh',
		},
		'bookingEvent': {
			top: '52px',
			width: '700px',
			height: '60vh',
		}
	},
	audioObj: {
		'bookingAlert': {
			'sos': true,
			'rejectBooking': true,
			'rejectBookingAutomatic': false,
			'driverCancelBooking': false,
		},
		'driverAlert': {
			'offlineWarning': true,
			'online': true,
			'offline': true
		},
		'sticky': {
			'add': true
		},
		'chat': {
			'messageReceive': true
		},
		'unassignTab': {
			'bookingAssignRemainder': true
		},
		'createBookingNotifyAlert': {
			'dispatcherAccept': false,
			'dispatcherDecline': false
		},
		'createBookingPopupAlert': {
			"createBookingPopupNotify": true
		}
	}
};
