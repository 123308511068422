import { Component, HostListener, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CallmethodService } from './services/callmethod.service';
import { EventMqttService } from './services/event-mqtt.service';
import { GlobalService } from './services/global.service';
import { PopupService } from './services/popup.service';
import { environment } from "src/environments/environment";
import { merge, of, fromEvent, Observable, Observer } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { DataShareService } from './services/data-share.service';
import nosleep from '@uriopass/nosleep.js';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {
	title = 'voila-cabs';
	fullPageLoader: boolean = true;
	conn = (navigator as any).connection;
	isInternetReload: boolean = false;

	constructor(
		private Router: Router,
		public Global: GlobalService,
		public Popup: PopupService,
		public CallMethod: CallmethodService,
		public mqtt: EventMqttService,
		public shareData: DataShareService,
		private renderer1: Renderer2
	) {
		this.renderer1.listen('window', 'click', (e: Event) => {
			this.Global.renderData = e;
			this.shareData.shareData({ 'function': 'renderData' });
		});

		this.createOnline$().subscribe(isOnline => {
			if (isOnline) {
				if (this.conn.effectiveType == '4g' || this.conn.effectiveType == '3g') {
					if (this.isInternetReload == true) {
						this.Global.noInternetConnection = "onInternet";
						this.shareData.shareData({ 'function': 'reloadData' });
						setTimeout(() => {
							this.Global.noInternetConnection = "internet";
						}, 3000);
					} else {
						this.Global.noInternetConnection = "internet";
					}
				} else {
					this.Global.noInternetConnection = "noInternet";
					this.isInternetReload = true;
				}
			} else {
				this.Global.noInternetConnection = "noInternet";
				this.isInternetReload = true;
			}
		});

		let JwtToken: string = "" + this.Global.getLocalStorage({ 'key': 'JwtToken' });
		let dispatcherId: any = this.Global.getLocalStorage({ 'key': 'dispatcherId' });

		if (JwtToken != undefined && JwtToken != '' && dispatcherId != undefined) {
			this.fullPageLoader = false;
			let getPageTitleLocalStorage = this.Global.getLocalStorage({ 'key': 'pageTitle' });
			let getFavIconLocalStorage = this.Global.getLocalStorage({ 'key': 'favIcon' });
			this.Global.companyDetails.companyId = this.Global.getLocalStorage({ 'key': 'companyId' });

			let pageTitle: any = document.getElementById('pageTitle') as HTMLTitleElement;
			pageTitle.innerHTML = getPageTitleLocalStorage;

			let favIcon = document.getElementById('favIcon') as HTMLLinkElement;
			favIcon.setAttribute('href', environment.baseUrl + environment.imageUrl + environment.CompanySetting + getFavIconLocalStorage);

			let colorCode = this.Global.getLocalStorage({ 'key': 'colorslist' });
			colorCode = colorCode.split(",");
			this.Global.first_color = colorCode[0];
			this.Global.second_color = colorCode[1];
			this.Global.third_color = colorCode[2];
		}
		else {
			this.Router.navigate(['sign-in']);
		}
	}

	ngOnInit(): void {

		this.preventSleep()

		let unassignBookingAlertTime = this.Global.getLocalStorage({ key: 'unassignBookingAlertTime' })
		if (unassignBookingAlertTime == undefined) {
			this.Global.unassignBookingAlertTime = environment.unassignBookingAlertTime;
		} else {
			this.Global.unassignBookingAlertTime = unassignBookingAlertTime;
		}

		let unassignBookingAlertOffTime = this.Global.getLocalStorage({ key: 'unassignBookingAlertOffTime' })
		if (unassignBookingAlertOffTime == undefined) {
			this.Global.unassignBookingAlertOffTime = environment.unassignBookingAlertOffTime;
		} else {
			this.Global.unassignBookingAlertOffTime = unassignBookingAlertOffTime;
		}

		//unassignBookingAlertcolor
		let unassignBookingAlertColor = this.Global.getLocalStorage({ key: 'unassignBookingAlertColor' });

		if (unassignBookingAlertColor == undefined) {
			this.Global.unassignBookingAlertColor = environment.bookingUnassignTimeColors.timeOut;
		} else {
			this.Global.unassignBookingAlertColor = unassignBookingAlertColor;
		}


		//cal unassignBooking alert time based on
		let calculateUnAssignBookingAlertTimeBasedOn = this.Global.getLocalStorage({ key: 'calculateUnAssignBookingAlertTimeBasedOn' })

		if (calculateUnAssignBookingAlertTimeBasedOn == undefined) {
			this.Global.calculateUnAssignBookingAlertTimeBasedOn = environment.calculateUnAssignBookingAlertTimeBasedOn;
		} else {
			this.Global.calculateUnAssignBookingAlertTimeBasedOn = calculateUnAssignBookingAlertTimeBasedOn;
		}

		//on page reload show shimmer
		let OnEventShowShimmer = this.Global.getLocalStorage({ key: 'OnEventShowShimmer' })
		if (OnEventShowShimmer == undefined) {
			this.Global.OnEventShowShimmer = environment.OnEventShowShimmer
		} else {
			this.Global.OnEventShowShimmer = OnEventShowShimmer
		}

		//onlineDriverList sort Array
		let sortOnlineDriverList = this.Global.getLocalStorage({ key: 'onlineDriverListArraySortBy' });
		if (sortOnlineDriverList == undefined) {
			this.Global.onlineDriverListSortBy = environment.onlineDriverListSortBy;
		} else {
			this.Global.onlineDriverListSortBy = sortOnlineDriverList;
		}

		//tarrif
		let tarrif = this.Global.getLocalStorage({ key: 'tarrif' });
		if (tarrif == undefined) {
			this.Global.tarrif = environment.tarrif;
		} else {
			this.Global.tarrif = tarrif;
		}

		//manage alert popup
		let manageAlertPopup = this.Global.getLocalStorage({ key: 'manageAlertPopup' });
		if (manageAlertPopup == undefined) {
			this.Global.manageAlertPopup = environment.manageAlertPopup;
		} else {
			this.Global.manageAlertPopup = manageAlertPopup;
		}

		let driverMarkerLabel = this.Global.getLocalStorage({ key: 'driverMarkerLabel' });
		if (driverMarkerLabel == undefined) {
			this.Global.driverMarkerLabel = environment.driverMarkerLabel;
		} else {
			this.Global.driverMarkerLabel = driverMarkerLabel;
		}

		let driverMarkerIcon = this.Global.getLocalStorage({ key: 'driverMarkerIcon' });
		if (driverMarkerIcon == undefined) {
			this.Global.driverMarkerIcon = environment.driverMarkerIcon;
		} else {
			this.Global.driverMarkerIcon = driverMarkerIcon;
		}

		let isShowAddressType = this.Global.getLocalStorage({ key: 'isShowAddressType' });
		if (isShowAddressType == undefined) {
			this.Global.isShowAddressType = environment.isShowAddressType;
		} else {
			this.Global.isShowAddressType = isShowAddressType;
		}

		let isCopyAccountId = this.Global.getLocalStorage({ key: 'isCopyAccountId' });
		if (isCopyAccountId == undefined) {
			this.Global.isCopyAccountId = environment.isCopyAccountId;
		} else {
			this.Global.isCopyAccountId = isCopyAccountId;
		}

		//pickup Delay 
		let pickupDelay = this.Global.getLocalStorage({ key: 'pickupDelay' });
		if (pickupDelay == undefined) {
			this.Global.pickupDelay = environment.pickupDelay;
		} else {
			this.Global.pickupDelay = pickupDelay;
		}

		//drop delay 
		let dropDelay = this.Global.getLocalStorage({ key: 'dropDelay' });
		if (dropDelay == undefined) {
			this.Global.dropDelay = environment.dropDelay;
		} else {
			this.Global.dropDelay = dropDelay;
		}

		//paymentTime Delay
		let isSetDriverLimit = this.Global.getLocalStorage({ key: 'isSetDriverLimit' });
		if (isSetDriverLimit == undefined) {
			this.Global.isSetDriverLimit = environment.isSetDriverLimit;
		} else {
			this.Global.isSetDriverLimit = isSetDriverLimit;
		}

		//Find Driver Settings
		let findDriverSettings = this.Global.getLocalStorage({ key: 'findDriverSettings' });
		if (findDriverSettings == undefined) {
			this.Global.findDriverSettings = environment.findDriverSettings;
		} else {
			this.Global.findDriverSettings = findDriverSettings;
		}

		//paymentTime Delay
		let paymentDelayTime = this.Global.getLocalStorage({ key: 'paymentDelayTime' });
		if (paymentDelayTime == undefined) {
			this.Global.paymentDelayTime = environment.paymentDelayTime;
		} else {
			this.Global.paymentDelayTime = paymentDelayTime;
		}

		//set minimum Assign Minutes
		let minimumAssignMinutes = this.Global.getLocalStorage({ key: 'minimumAssignMinutes' });
		if (minimumAssignMinutes == undefined) {
			this.Global.minimumAssignMinutes = environment.minimumAssignMinutes;
		} else {
			this.Global.minimumAssignMinutes = minimumAssignMinutes;
		}

		//waitTime color
		let waitTimeColor = this.Global.getLocalStorage({ key: 'waitTimeColor' });
		if (waitTimeColor == undefined) {
			this.Global.waitTimeColor = environment.waitTimeColor;
		} else {
			this.Global.waitTimeColor = waitTimeColor;
		}

		//audio settings
		let audioObj = this.Global.getLocalStorage({ key: 'audioObj' });
		// console.log("outside app", audioObj);

		if (audioObj == undefined) {
			this.Global.audioObj = environment.audioObj;
			// console.log("===== if global this.Global.audioObj :: ", this.Global.audioObj);
		} else {
			this.Global.audioObj = audioObj;
			// console.log("===== else global this.Global.audioObj :: ", this.Global.audioObj);
		}

		//Nearest Driver While Creating Booking
		let showNearestDriveOnCreateBooking = this.Global.getLocalStorage({ key: 'showNearestDriveOnCreateBooking' });
		if (isSetDriverLimit == undefined) {
			this.Global.showNearestDriveOnCreateBooking = environment.showNearestDriveOnCreateBooking;
		} else {
			this.Global.showNearestDriveOnCreateBooking = showNearestDriveOnCreateBooking;
		}

	}


	@HostListener('document:visibilitychange', ['$event'])
	onVisibilityChange(event: Event): void {
		if (document.hidden) {
			// System is in sleep mode
			console.log('System is in sleep mode');
		} else {
			// System has woken up from sleep mode
			console.log('System has woken up from sleep mode');
			// You can perform any desired actions here
		}
	}

	createOnline$() {
		return merge(of(navigator.onLine),
			fromEvent(window, 'online').pipe(mapTo(true)),
			fromEvent(window, 'offline').pipe(mapTo(false)),
			new Observable((sub: Observer<boolean>) => {
				sub.next(navigator.onLine);
				sub.complete();
			}));

	}

	// Prevent sleep mode
	preventSleep() {
		const noSleep = new nosleep();
		console.log('****** noSleep ******', noSleep);
		// Request a wake lock to prevent the screen from sleeping
		noSleep.enable();

		// You can release the wake lock when you're done
		// noSleep.disable();
	}

}
