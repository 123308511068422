import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CallmethodService } from '../services/callmethod.service';
import { GlobalService } from '../services/global.service';


@Component({
  selector: 'app-zoneque',
  templateUrl: './zoneque.component.html',
  styleUrls: ['./zoneque.component.scss']
})
export class ZonequeComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  constructor(
    public Global: GlobalService,
    public CallMethod: CallmethodService,
  ) { }

  dispatcherDetails: any
  zoneViewList: any[] = []
  zoneQueList: any[] = []
  disableBtn: boolean = false
  isLoading: boolean = false;
  isDataMissing: boolean = false;
  warningMessage: string = 'No data found for the selected zone!';
  ngOnInit(): void {
    this.Global.dashboard().then((result: any) => {
      if (result.status) {
        console.log('Entered in ZoneQue component______', this.Global.surgePriceZone)
        this.zoneViewList = this.Global.surgePriceZone.slice(1)
        console.log('****** this.zoneViewList ******', this.zoneViewList);
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

  activeIndex: number | null = null;
  selectedZone: any;
  zoneSelect() {
    this.isLoading = true;
    this.zoneQueList = []
    this.CallMethod.getDriverQueueList({
      "zoneId": this.selectedZone.zoneId
    }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      console.log('--------------------- result ---------------------', result)
      console.log('--------------------- result.status ---------------------', result.status)
      if (result.status) {
        this.isDataMissing = false
        this.isLoading = false
        this.zoneQueList = result.data;
      }
      else if (!result.status) {
        this.isDataMissing = true
        this.warningMessage = `No data found for ${this.selectedZone.zoneName} !`;
        this.isLoading = false
        this.zoneQueList = [];
      }
    })
  }
  // Add an array to track the state of each accordion
  activeIndexes: boolean[] = [];

  // Toggle function to open/close specific accordion items
  toggleAccordion(index: number): void {
    this.activeIndexes[index] = !this.activeIndexes[index];
  }

  sanitizeMobileNumber(mobile: string): string {
    return mobile.replace(/\s+/g, '');
  }

}
