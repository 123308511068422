import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CallmethodService } from '../services/callmethod.service';
import { GlobalService } from '../services/global.service';
import { PopupService } from '../services/popup.service';
import { environment } from "src/environments/environment";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare const clickButton: any;

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
	// country code picker with flegs
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;

	private unsubscribe$ = new Subject<void>();


	preferredCountries: any = [CountryISO.India, CountryISO.Kuwait];
	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Kuwait];
	}

	EmailOrPhone: number = 1;

	constructor(
		private Router: Router,
		public CallMethod: CallmethodService,
		public FormBuilder: FormBuilder,
		public Global: GlobalService,
		public Popup: PopupService
	) {
		let loginWithEmailOrPhone: number = this.Global.getLocalStorage({ 'key': 'loginWithEmailOrPhone' });
		if (loginWithEmailOrPhone != undefined) {
			this.EmailOrPhone = loginWithEmailOrPhone;
		}
	}

	ngOnInit(): void {
		this.Global.setTheme();
		this.getCompanyDetails();
		let theme = this.Global.getLocalStorage({ key: 'themeType' })
		if (this.Global.getLocalStorage({ key: 'themeType' }) != undefined) {
			let switchTheme = <HTMLElement>document.getElementById('themeSwitch');
			if (theme == 'darkTheme') {
				switchTheme.setAttribute('checked', 'true');
				switchTheme.classList.remove('lightSwitch');
				switchTheme.classList.add('darkSwitch');
			} else {
				switchTheme.removeAttribute('checked');
				switchTheme.classList.add('lightSwitch');
				switchTheme.classList.remove('darkSwitch');
			}
		}
		let jwtToken = this.Global.getLocalStorage({ 'key': 'JwtToken' });
		if (jwtToken != undefined) {
			let companyId = this.Global.getLocalStorage({ 'key': 'companyId' });
			let userName = this.Global.getLocalStorage({ 'key': 'userName' });
			let password = this.Global.getLocalStorage({ 'key': 'password' });
			let requestObj = {
				"userName": userName, // email or mobile
				"companyId": companyId,
				"password": password
			}
			setTimeout(() => {
				this.login(requestObj);
			}, 200);
		}


	}

	fullPageLoader: boolean = false;
	getCompanyDetails() {
		let getCompanyDetailsObj = {
			"domain": window.location.host
		}

		this.fullPageLoader = true;
		this.CallMethod.getCompanyDetails(getCompanyDetailsObj).pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
			if (data.status == true) {
				let coumpnyData = data.companyData;

				this.Global.setLocalStorage({
					'key': 'companyId',
					'value': coumpnyData.companyId
				});
				this.Global.setLocalStorage({
					'key': 'compayName',
					'value': coumpnyData.compayName
				});
				this.Global.setLocalStorage({
					'key': 'favIcon',
					'value': coumpnyData.dispatcher_favicon
				});
				this.Global.setLocalStorage({
					'key': 'logo',
					'value': coumpnyData.dispatcher_logo
				});
				this.Global.setLocalStorage({
					'key': 'pageTitle',
					'value': coumpnyData.pageTitle
				});
				this.Global.setLocalStorage({
					'key': 'title',
					'value': coumpnyData.title
				});

				if (this.Global.getLocalStorage({ 'key': 'colorslist' })) {
					let getColorCode = this.Global.getLocalStorage({ 'key': 'colorslist' })
					let colorCode = getColorCode.split(",");
					this.Global.first_color = colorCode[0];
					this.Global.second_color = colorCode[1];
					this.Global.third_color = colorCode[2];
				}
				else {
					this.Global.setLocalStorage({
						'key': 'colorslist',
						'value': coumpnyData.colorCode
					});

					let colorCode = coumpnyData.colorCode.split(",");
					this.Global.first_color = colorCode[0];
					this.Global.second_color = colorCode[1];
					this.Global.third_color = colorCode[2];
				}

				this.Global.setLocalStorage({
					'key': 'compnayColorsList',
					'value': coumpnyData.colorCode
				});

				this.Global.companyDetails.colorslist = coumpnyData.colorCode;
				this.Global.companyDetails.companyId = coumpnyData.companyId;
				this.Global.companyDetails.companyTitle = coumpnyData.pageTitle;
				this.Global.companyDetails.companyLogo = environment.baseUrl + environment.imageUrl + environment.CompanySetting + coumpnyData.dispatcher_logo;
				this.Global.companyDetails.companyFavIcon = environment.baseUrl + environment.imageUrl + environment.CompanySetting + coumpnyData.dispatcher_favicon;
				this.Global.companyDetails.companyName = coumpnyData.title;

				let pageTitle: any = document.getElementById('pageTitle') as HTMLTitleElement;
				pageTitle.innerHTML = coumpnyData.pageTitle;

				let favIcon = document.getElementById('favIcon') as HTMLLinkElement;
				favIcon.setAttribute('href', environment.baseUrl + environment.imageUrl + environment.CompanySetting + coumpnyData.dispatcher_favicon);
			} else {
				this.Router.navigate(['/page-not-found404'], { skipLocationChange: true });
			}

			this.fullPageLoader = false;
		}, (error) => {
			console.log("error=>>>", error);
		});
	}

	Submit() {
		clickButton();
	}

	onEnter() {
		this.LoginBtnApiCall();
	}

	LoginBtn: boolean = false;
	phoneForm: any = new FormGroup({
		phone: new FormControl([Validators.required])
	});
	LoginBtnApiCall() {
		this.LoginBtn = true;
		let username;
		let password;
		let loginobj: any;

		let numberJson = this.phoneForm.get('phone').value;
		let dialCode = '';
		let phoneNo = '';

		if (numberJson != null) {
			dialCode = numberJson.dialCode;
			phoneNo = numberJson.number;
		}

		if (this.EmailOrPhone == 0) {
			dialCode.charAt(0) == "+";
			dialCode = dialCode.slice(1);
			if (phoneNo.charAt(0) == "0") {
				phoneNo = phoneNo.slice(1);
			} else {
				phoneNo = phoneNo;
			}
			phoneNo = phoneNo.replace(/ /g, "");
		}

		if (this.EmailOrPhone == 0) {
			username = dialCode + ' ' + phoneNo;
		}
		else {
			username = document.getElementById('email') as HTMLInputElement;
			username = username.value;
		}
		password = document.getElementById('password') as HTMLInputElement;

		if (username.length == 1 && this.EmailOrPhone == 0 && password.value == "") {
			this.Popup.toasterMessage({
				'status': 'error',
				'message': 'Enter Phone Number & Password !',
				'title': 'Oops..',
			});
			this.LoginBtn = false;
		}
		else if (username.length == 0 && this.EmailOrPhone == 1 && password.value == "") {
			this.Popup.toasterMessage({
				'status': 'error',
				'message': 'Enter Phone Number & Password !',
				'title': 'Oops..',
			});
			this.LoginBtn = false;
		}
		else if (username.length == 1 && this.EmailOrPhone == 0) {
			this.Popup.toasterMessage({
				'status': 'error',
				'message': 'Enter  Phone Number !',
				'title': 'Oops..',
			});
			this.LoginBtn = false;
		}
		else if (username.length == 0 && this.EmailOrPhone == 1) {
			this.Popup.toasterMessage({
				'status': 'error',
				'message': 'Enter your Email  !',
				'title': 'Oops..',
			});
			this.LoginBtn = false;
		}
		else if (password.value == "") {
			this.Popup.toasterMessage({
				'status': 'error',
				'message': 'Enter Password !',
				'title': 'Oops..',
			});
			this.LoginBtn = false;
		}
		else {
			let companyId = this.Global.getLocalStorage({ 'key': 'companyId' });

			loginobj = {
				"userName": username, // email or mobile
				"companyId": companyId,
				"password": password.value
			}
			this.login(loginobj)
		}
	}

	login(requestObj: any) {
		this.LoginBtn = true;

		this.CallMethod.login(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			if (result.status == true) {
				localStorage.removeItem('allDriverscachedDriversData');
				this.Global.lightThemeButtonChecked = result.settings.lightThemeButtonChecked;
				if (result.settings.lightThemeButtonChecked == true) {
					this.Global.setTheme('lightTheme');
					this.Global.setLocalStorage({
						'key': 'themeType',
						'value': 'lightTheme'
					});
				} else {
					this.Global.setLocalStorage({
						'key': 'themeType',
						'value': 'darkTheme'
					});
					this.Global.setTheme('darkTheme');
				}

				this.Global.isMapDark = result.settings.isMapDark;
				if (result.settings.isMapDark != true) {
					this.Global.setLocalStorage({
						'key': 'mapThemeType',
						'value': 'lightTheme'
					});
					this.Global.setMapTheme();
				} else {
					this.Global.setLocalStorage({
						'key': 'mapThemeType',
						'value': 'darkTheme'
					});
					this.Global.setMapTheme();
				}


				this.Global.isShowAddressType = result.settings.isShowAddressType;
				this.Global.setLocalStorage({ 'key': 'isShowAddressType', 'value': result.settings.isShowAddressType });

				this.Global.unassignBookingAlertTime = result.settings.unassignBookingAlertTime;
				this.Global.setLocalStorage({
					'key': 'unassignBookingAlertTime',
					'value': result.settings.unassignBookingAlertTime
				});

				this.Global.unassignBookingAlertOffTime = result.settings.unassignBookingAlertOffTime;
				this.Global.setLocalStorage({
					'key': 'unassignBookingAlertOffTime',
					'value': result.settings.unassignBookingAlertOffTime
				});

				this.Global.isSetDriverLimit = result.settings.isSetDriverLimit;
				this.Global.setLocalStorage({
					'key': 'isSetDriverLimit',
					'value': result.settings.isSetDriverLimit
				});

				this.Global.calculateUnAssignBookingAlertTimeBasedOn = result.settings.calculateUnAssignBookingAlertTimeBasedOn;
				this.Global.setLocalStorage({
					'key': 'calculateUnAssignBookingAlertTimeBasedOn',
					'value': result.settings.calculateUnAssignBookingAlertTimeBasedOn
				});

				this.Global.unassignBookingAlertColor = result.settings.unassignBookingAlertColor;
				this.Global.setLocalStorage({
					'key': 'unassignBookingAlertColor',
					'value': result.settings.unassignBookingAlertColor
				});

				this.Global.OnEventShowShimmer = result.settings.OnEventShowShimmer;
				this.Global.setLocalStorage({
					'key': 'OnEventShowShimmer',
					'value': result.settings.OnEventShowShimmer
				});

				this.Global.findDriverSettings = result.settings.findDriverSettings;
				this.Global.setLocalStorage({
					'key': 'findDriverSettings',
					'value': result.settings.findDriverSettings
				});

				this.Global.manageAlertPopup = result.settings.manageAlertPopup;
				this.Global.setLocalStorage({
					'key': 'manageAlertPopup',
					'value': result.settings.manageAlertPopup
				});

				this.Global.isCopyAccountId = result.settings.isCopyAccountId;
				this.Global.setLocalStorage({ 'key': 'isCopyAccountId', 'value': result.settings.isCopyAccountId });

				this.Global.driverMarkerIcon = result.settings.driverMarkerIcon;
				this.Global.setLocalStorage({ 'key': 'driverMarkerIcon', 'value': result.settings.driverMarkerIcon });

				this.Global.driverMarkerLabel = result.settings.driverMarkerLabel;
				this.Global.setLocalStorage({ 'key': 'driverMarkerLabel', 'value': result.settings.driverMarkerLabel });

				this.Global.audioObj = result.settings.audioObj;
				this.Global.setLocalStorage({ 'key': 'audioObj', 'value': result.settings.audioObj });

				this.Global.onlineDriverListSortBy = result.settings.onlineDriverListSortBy;
				this.Global.setLocalStorage({ 'key': 'onlineDriverListArraySortBy', 'value': result.settings.onlineDriverListSortBy });

				this.Global.tarrif = result.settings.tarrif;
				this.Global.setLocalStorage({ 'key': 'tarrif', 'value': result.settings.tarrif });

				this.Global.minimumAssignMinutes = result.settings.minimumAssignMinutes;
				this.Global.setLocalStorage({ 'key': 'minimumAssignMinutes', 'value': result.settings.minimumAssignMinutes });

				this.Global.pickupDelay = result.settings.pickupDelay;
				this.Global.setLocalStorage({
					key: "pickupDelay",
					value: result.settings.pickupDelay,
				});

				this.Global.dropDelay = result.settings.dropDelay;
				this.Global.setLocalStorage({
					key: "dropDelay",
					value: result.settings.dropDelay,
				});

				this.Global.paymentDelayTime = result.settings.paymentDelayTime;
				this.Global.setLocalStorage({
					key: "paymentDelayTime",
					value: result.settings.paymentDelayTime,
				});

				this.Global.waitTimeColor = result.settings.waitTimeColor;
				this.Global.setLocalStorage({
					key: "waitTimeColor",
					value: result.settings.waitTimeColor,
				});
				this.Global.showNearestDriveOnCreateBooking = result.settings.showNearestDriveOnCreateBooking;
				this.Global.setLocalStorage({
					key: "showNearestDriveOnCreateBooking",
					value: result.settings.showNearestDriveOnCreateBooking,
				});

				this.Global.waitTimeDelayColor = result.settings.waitTimeDelayColor;
				this.Global.setLocalStorage({
					key: "waitTimeDelayColor",
					value: result.settings.waitTimeDelayColor,
				});
				this.Global.createBookingShowsPopup = result.settings.createBookingShowsPopup;

				this.Global.setLocalStorage({
					'key': 'createBookingShowsPopup',
					'value': this.Global.createBookingShowsPopup
				});

				this.Global.setLocalStorage({
					'key': 'JwtToken',
					'value': result.jwtToken
				});
				this.Global.setRoleJson(result.dispatcherRoleJson)
				this.Global.setLocalStorage({
					'key': 'dispatcherRoleName',
					'value': result.dispatcherRoleName
				});

				this.Global.setLocalStorage({
					'key': 'dispatcherId',
					'value': result.dispatcherId
				});

				this.Global.setLocalStorage({
					'key': 'openPage',
					'value': 'home'
				});
				this.Global.setLocalStorage({
					'key': 'userName',
					'value': requestObj.userName
				});
				this.Global.setLocalStorage({
					'key': 'password',
					'value': requestObj.password
				});
				this.Global.setLocalStorage({ 'key': 'createBookingShowsPopup', 'value': result.settings.createBookingShowsPopup });
				this.Global.dashboard().then((res: any) => {
					if (res.status) {
						this.fullPageLoader = true;
					}
				});

				// this.Global.openPage = 'home';
				this.Router.navigateByUrl('home');
			}
			else {
				if (result.statusCode == 1) {
					this.Popup.toasterMessage({
						'status': 'error',
						'title': 'Oops..',
						'message': 'incorrect username !',
					});
					this.LoginBtn = false;
				}
				else if (result.statusCode == 2) {
					this.Popup.toasterMessage({
						'status': 'error',
						'title': 'Oops..',
						'message': 'Inactive Dispatcher!',
					});
					this.LoginBtn = false;
				}
				else if (result.statusCode == 3) {
					this.Popup.toasterMessage({
						'status': 'error',
						'title': 'Oops..',
						'message': 'incorrect Password  !',
					});
					this.LoginBtn = false;
				}
			}

			// this.LoginBtn = false;
		});
	}


	sucess = 1;
	SelectEmail() {
		this.EmailOrPhone = 0;
		this.Global.setLocalStorage({
			'key': 'loginWithEmailOrPhone',
			'value': 0
		});
	} SelectPhone() {
		this.EmailOrPhone = 1;
		this.Global.setLocalStorage({
			'key': 'loginWithEmailOrPhone',
			'value': 1
		});
	} BtnNextSendOtp() {
		this.sucess = 2;
	} BtnNextEmailPage() {
		this.sucess = 3;
	} BtnNext() {
		this.sucess = 4;
	}

	// hide show password
	fieldTextType: boolean = false;
	toggleFieldTextType() {
		this.fieldTextType = !this.fieldTextType;
	}

	signinThemeChange() {
		let switchTheme = <HTMLElement>document.getElementById('themeSwitch');

		if (switchTheme.getAttribute('checked') == null) {
			switchTheme.setAttribute('checked', 'true');
			switchTheme.classList.remove('lightSwitch');
			switchTheme.classList.add('darkSwitch');
			this.Global.setTheme('darkTheme');
			this.Global.setLocalStorage({
				'key': 'themeType',
				'value': 'darkTheme'
			});
		} else {
			switchTheme.removeAttribute('checked');
			switchTheme.classList.add('lightSwitch');
			switchTheme.classList.remove('darkSwitch');
			this.Global.setTheme('lightTheme');
			this.Global.setLocalStorage({
				'key': 'themeType',
				'value': 'lightTheme'
			});
		}
	}
}